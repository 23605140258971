import React, { useState, useRef } from "react";
import ReactDOM from 'react-dom/client';
import { defer, uniqueId } from "lodash";
import './index.css';

type UserQuery = {
  id: string;
  question: string;
  response: string;
  sentAt: Date;
}

const responses = [
  "👋 Hey, welcome to Norn Iron.",
  "\"Norn Iron\" is how people in Northern Ireland refer to their wonderful little country.",
  "I have no idea what you're asking, these are just automated responses.",
  "My name is Colin Young. I am a software engineer from Belfast, Northern Ireland.",
  "I have around 15 years experience working primarily with start ups.",
  "My main tech stacks are Typescript, React, React Native and Ruby (usually on Rails).",
  "I am currently working for Kairos Sports Tech as their Principle Software Engineer."
];

const App = () => {
  const [inputValue, setInputValue] = useState("");
  const [userQueries, setUserQueries] = useState<UserQuery[]>([]);
  const [showError, setShowError] = useState(false);

  return (
    <div className="flex flex-col h-full bg-gray-900 overflow-hidden">
      <div className="absolute left-0 top-0 h-72 w-full bg-gradient-to-b from-gray-900 via-gray-900 z-30" />

      <div className="flex flex-col w-full gap-20 px-10 pb-10 h-full justify-end responses-container overflow-hidden">
        {userQueries.map((query, index) => {
          const isMostRecent = index === userQueries.length - 1;
          return (
            <div key={query.id} className="flex flex-col w-full">
              <span className={`text-white ${isMostRecent ? "text-xl" : "text-base"} opacity-50 whitespace-nowrap overflow-hidden text-ellipsis`}>{query.question}</span>
              <span className={`text-white opacity-20 text-sm"`}>{query.sentAt.toISOString()}</span>
              <span className={`text-white ${isMostRecent ? "text-2xl opacity-80" : "text-lg opacity-60"} mt-4`}>{query.response}</span>
            </div>
          )
        })}
      </div>

      <div className="flex gap-6 input-container">
        <span className="text-gray-500 ml-10 text-2xl md:text-4xl">&gt;_</span>
        <form
          className="flex flex-grow flex-col gap-2"
          onSubmit={(ev) => {
            ev.nativeEvent.preventDefault();

            if (inputValue.length === 0) {
              return setShowError(true);
            }

            setUserQueries(queries => {
              return [...queries, {
                id: uniqueId(),
                question: inputValue,
                response: responses[queries.length % responses.length],
                sentAt: new Date()
              }];
            })

            setInputValue("");
            setTimeout(() => {
              document.body.scrollTo(0, document.body.scrollHeight);
            }, 300);
          }}
        >
          <input
            type="text"
            value={inputValue}
            autoFocus
            onChange={(ev) => {
              setShowError(false);
              setInputValue(ev.currentTarget.value)
            }}
            className="bg-transparent text-white w-full outline-none placeholder text-3xl md:text-4xl"
            placeholder="norniron.dev"
          />
          <span className="text-sm text-red-400">
            {showError ? "Please add a query" : ""}&nbsp;
          </span>
        </form>
      </div>
    </div>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
